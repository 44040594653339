import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Table,
  Input,
  Button,
  Label,
} from "reactstrap";
import ModalManagementDetails from "../Whatsapp/components/ModalManagementDetails"
import { Link, withRouter } from "react-router-dom";
import { useDispatch, connect } from "react-redux";
import { gestionesContactoFilter, gestionesContactoList } from "../../store/actions";
import Pagination from "../../components/Common/Pagination";
import Loader from "../../components/Common/Loader";
import Select from "react-select";
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { getAgent, getCampaignFullList } from "../../helpers/backend";

function ManagementList(props) {
  const dispatch = useDispatch()
  const [detallesModal, setDetallesModal] = useState(false);
  const [GestionDetallada, setGestionDetallada] = useState(false);
  const [fechaInicio, setFechaInicio] = useState("");
  const [fechaFin, setFechaFin] = useState("");
  const [estado, setEstado] = useState('');
  const [agente, setAgente] = useState('');
  const [campaña, setCampaña] = useState('');
  const [agent, setAgent] = useState({});
  const [selectedAgents, setSelectedAgents] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState([]);
  const [selectedEstado, setSelectedEstado] = useState([]);
  const [selectedType, setSelectedType] = useState([]);
  const [isLead, setIsLead] = useState(false);
  const [type, setType] = useState('');

  const handleSelectChange = (selectedOptions) => {
    const selectedContactData = selectedOptions ? selectedOptions.map(option => option.value).join(',') : '';
    setSelectedAgents(selectedOptions || []);
    setAgente(selectedContactData);
  };
  const handleSelectCampaignChange = (selectedOptions) => {
    setSelectedCampaign(selectedOptions || []);
    setCampaña(selectedOptions.value);
  };
  const handleSelectEstadoChange = (selectedOptions) => {
    setSelectedEstado(selectedOptions || []);
    setEstado(selectedOptions.value); // Asumo que setAgente está definido en otro lugar y actualiza el estado correspondiente
  };

  const getAgentData = async () => {
    const response = await getAgent();
    const data = response.data;
    setAgent(data)
  }
  const [campaignData, setCampaignData] = useState({});
  const getCampaignData = async () => {
    const response = await getCampaignFullList();
    const data = response.data;
    setCampaignData(data);
  };
  const handleSelectTypeChange = (selectedOptions) => {
    setSelectedType(selectedOptions || []);
    setType(selectedOptions.value); // Asumo que setAgente está definido en otro lugar y actualiza el estado correspondiente
  };
  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(gestionesContactoFilter(`?fecha_inicio=${fechaInicio}&fecha_fin=${fechaFin}&estado=${estado}&agents=${agente}&campaign=${campaña}&type=${type}&is_leads=${isLead}`));
  };
  const resetSearch = () => {
    dispatch(gestionesContactoFilter(`?search=`));
    setFechaFin("");
    setFechaInicio("");
    setSelectedAgents("");
    setSelectedCampaign("");
    setSelectedEstado("");
    setSelectedType("") 
    setIsLead(false)
    setType("")
  };

  const handleDetallesModal = () => {
    setDetallesModal(false);
  };

  useEffect(() => {
    dispatch(gestionesContactoList())
    getAgentData();
    getCampaignData();
  }, [dispatch]);


  const params = {
    count: props.gestionesContacto.count,
    next: props.gestionesContacto.next,
    previous: props.gestionesContacto.previous,
    results: props.gestionesContacto.results,
  };
  console.log(params);
  return (
    <div className="page-content">

      {detallesModal ? (
        <ModalManagementDetails
          isOpen={detallesModal}
          onCloseClick={handleDetallesModal}
          GestionDetallada={GestionDetallada}
        ></ModalManagementDetails>
      ) : null}

      <MetaTags>
        <title>TrueContact | Gestiones del Contacto </title>
      </MetaTags>
      <Container fluid>
        <Breadcrumbs title="TrueContact" breadcrumbItem=" Gestión del Contacto " />
        {props.loading ? (
          <Loader />
        ) : (
          <div>
            <Card>
            <CardBody>
                  <Row className="d-flex justify-content-center">
                    <Col lg={12}>
                      <Row>
                        <Col>
                          <Input
                            type="date"
                            className="form-control me-1"
                            name="fechaInicio"
                            placeholder="Fecha Inicio"
                            value={fechaInicio}
                            onChange={(e) => setFechaInicio(e.target.value)}
                          />
                        </Col>
                        <Col>
                          <Input
                            type="date"
                            className="form-control me-1"
                            name="fechaFin"
                            placeholder="Fecha Fin"
                            value={fechaFin}
                            onChange={(e) => setFechaFin(e.target.value)}
                          />
                        </Col>
                        <Col>
                          <Select
                            onChange={handleSelectEstadoChange}
                            options={[
                              { value: 'EN_GESTION', label: 'En Gestión' },
                              { value: 'VENCIDO', label: 'Vencido' },
                              { value: 'FINALIZADO', label: 'Finalizado' },
                              { value: 'PENDIENTE', label: 'Pendiente' }
                            ]}
                            placeholder="Estado"
                            value={selectedEstado}
                          />
                        </Col>
                        <Col>
                          <Select
                            className="me-1"
                            options={Array.isArray(agent)
                              ? agent.map((item) => ({
                                label: item.get_full_name,
                                value: item.id,
                              }))
                              : []}
                            value={selectedAgents}
                            onChange={handleSelectChange}
                            isMulti={true}
                            placeholder="Agentes"
                            menuPosition="fixed"
                          />
                        </Col>
                        <Col>
                          <Select
                            className="me-1"
                            options={Array.isArray(campaignData)
                              ? campaignData.map((item) => ({
                                label: item.name,
                                value: item.id,
                              }))
                              : []}
                            onChange={handleSelectCampaignChange}
                            value={selectedCampaign}
                            menuPosition="fixed"
                            placeholder="Campañas"
                          />
                        </Col>
                        <Col>
                          <Select
                            className="me-1"
                            options={[
                              { value: 'WhatsApp', label: 'WhatsApp' },
                              { value: 'Telefonia - Manual', label: 'Telefonía - Manual' }
                            ]}
                            onChange={handleSelectTypeChange}
                            value={selectedType}
                            menuPosition="fixed"
                            placeholder="Tipo"
                          />
                        </Col>
                        <Col>
                          <div className="form-check">
                            <Input
                              type="checkbox"
                              className="form-check-input"
                              id="isLeadCheck"
                              checked={isLead}
                              onChange={(e) => setIsLead(e.target.checked)}
                            />
                            <Label className="form-check-label" for="isLeadCheck">
                              ¿Lead?
                            </Label>
                          </div>
                        </Col>
                        <Col lg={2}>
                          <Button color="warning" className="btn-sm me-1 mt-1" onClick={onSubmit}>
                            <b>Consultar</b>
                          </Button>
                          <Button color="danger" className="btn-sm me-1 mt-1" onClick={resetSearch}>
                            <b>Borrar</b>
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
            </Card>
            <Card className="col-lg-12 mx-auto  mt-3">
              {params.count === 0 ? (
                <CardBody>
                  <h4 className="card-title">No se encontraron resultados</h4>
                </CardBody>
              ) : (
                <CardBody>
                  <h2 className="font-size-20">Historial</h2>
                  <p><b>Total de Gestiones: {params.count} </b></p>
                  <div className="table-responsive">
                    <Table
                      className="table  mb-4 text-center "
                      hover
                      responsive
                    >
                      <thead className="table-dark">
                        <tr>
                          <th>#</th>
                          <th>Nombre</th>
                          <th>Fecha</th>
                          <th>Campaña</th>
                          <th>Teléfono</th>
                          <th>Canal de Comunicación</th>
                          <th>Agente</th>
                          <th>Estado</th>
                          <th>Acción</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Array.isArray(params.results)
                          ? params.results.map((gestion, idx) => (
                            <tr key={idx}>
                              <th scope="row">{idx + 1}</th>
                              <td>{gestion.contacto}</td>
                              <td>{gestion.created_date}</td>
                              <td>{gestion.campaign[0] && gestion.campaign.map(item => item[0])}</td>
                              <td>{gestion.conversation}</td>
                              <td>{gestion.type}</td>
                              <td>{gestion.agent ? gestion.agent.map(item => item[0]) : null }</td>
                              <td>
                                {gestion.estado === "FINALIZADO" ? (
                                  <span className="badge bg-success">
                                    Finalizado
                                  </span>
                                ) : gestion.estado === "PENDIENTE" ? (
                                  <span className="badge bg-warning">
                                    Pendiente
                                  </span>
                                ) : gestion.estado === "VENCIDO" ? (
                                  <span className="badge bg-danger">
                                    Vencido
                                  </span>
                                ) : gestion.estado === "EN_GESTION" ? (
                                  <span
                                    className="badge "
                                    style={{
                                      background: "rgb(111, 66, 193)",
                                    }}
                                  >
                                    En Gestión
                                  </span>
                                ) : null}{" "}
                              </td>
                              <td>
                                {gestion.estado === "FINALIZADO" || gestion.estado === "VENCIDO" ? (
                                  <Link to="#">
                                    <span
                                      className="fas fa-eye fa-lg"
                                      onClick={() => {
                                        setDetallesModal(true);
                                        setGestionDetallada(gestion);
                                      }} >
                                    </span>
                                  </Link>
                                ) : null}
                                {gestion.estado === "EN_GESTION" ? (
                                  <Link to="#">
                                    <span
                                      className="fas fa-pen fa-lg"
                                      onClick={() => {
                                        setDetallesModal(true);
                                        setGestionDetallada(gestion);
                                      }} >
                                    </span>
                                  </Link>
                                ) : null}
                              </td>
                            </tr>
                          ))
                          : []}
                      </tbody>
                    </Table>
                  </div>
                  <Pagination
                    count={params.count}
                    next={params.next}
                    previous={params.previous}
                    limit={30}
                    baseUrl="contact/gestionContacto/"
                    filterFunction={gestionesContactoFilter}
                  />

                  <Row className="col-lg-12 justify-content-center">
                    <Col md={2} >
                      <Link className="btn btn-secondary text-dark ms-5" to={`/dashboard/`}>
                        Volver
                      </Link>
                    </Col>
                  </Row>
                </CardBody>
              )}
            </Card>
          </div>
        )}
      </Container>
    </div>
  );
}

const mapStateToProps = (state) => {
  const { error, loading, gestionesContacto } = state.GestionesContacto;
  return { error, loading, gestionesContacto };
};

export default withRouter(connect(mapStateToProps)(ManagementList));

