import React, { useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import TaskColumn from './TaskColumn';
import TaskEditor from './TaskEditor';
import './TaskFlow.css';
import { MetaTags } from 'react-meta-tags';
import { Button, Container } from 'reactstrap';

const TaskFlow = () => {
  const [taskFlows, setTaskFlows] = useState([
    { id: 1, title: 'Sala', tasks: [{ id: 1, title: 'Barrer' }, { id: 2, title: 'Trapear' }] },
    { id: 2, title: 'Cocina', tasks: [{ id: 3, title: 'Lavar platos' }] },
    { id: 3, title: 'Baños', tasks: [] },
    { id: 4, title: 'Lavandero', tasks: [] }
  ]);

  // Función para mover tareas entre columnas
  const moveTask = (taskIndex, fromColumnIndex, toColumnIndex) => {
    const newTaskFlows = [...taskFlows];
    const [movedTask] = newTaskFlows[fromColumnIndex].tasks.splice(taskIndex, 1);
    newTaskFlows[toColumnIndex].tasks.push(movedTask);
    setTaskFlows(newTaskFlows);
  };

  // Función para agregar una nueva tarea en la primera columna
  const addTask = (taskTitle) => {
    const newTask = { id: Date.now(), title: taskTitle };
    const newTaskFlows = [...taskFlows];
    newTaskFlows[0].tasks.push(newTask);
    setTaskFlows(newTaskFlows);
  };

  // Función para eliminar una tarea de una columna específica
  const deleteTask = (columnIndex, taskIndex) => {
    const newTaskFlows = [...taskFlows];
    newTaskFlows[columnIndex].tasks.splice(taskIndex, 1);
    setTaskFlows(newTaskFlows);
  };

  // Función para agregar una nueva columna (flujo)
  const addColumn = (columnTitle) => {
    const newColumn = { id: Date.now(), title: columnTitle, tasks: [] };
    setTaskFlows([...taskFlows, newColumn]);
  };

  // Función para eliminar una columna (flujo)
  const deleteColumn = (columnIndex) => {
    const newTaskFlows = [...taskFlows];
    newTaskFlows.splice(columnIndex, 1);
    setTaskFlows(newTaskFlows);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>TrueContact | Whatsapp </title>
        </MetaTags>
        <Container fluid>
          <DndProvider backend={HTML5Backend}>
          <Button className='mb-1 btn-info' onClick={() => addColumn('Nuevo Flujo')}>Agregar Nuevo Flujo</Button>
          <TaskEditor onSave={addTask} />
            <div className="task-flow">

              <div className="task-columns mt-3" style={{ display: 'flex', flexWrap: 'wrap' }}>
                {taskFlows.map((flow, index) => (
                  <TaskColumn
                    key={flow.id}
                    title={flow.title}
                    tasks={flow.tasks}
                    moveTask={moveTask}
                    deleteTask={deleteTask}
                    columnIndex={index}
                    deleteColumn={() => deleteColumn(index)}
                  />
                ))}
              </div>
            </div>
          </DndProvider>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default TaskFlow;
